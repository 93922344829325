const React = require("react")
const { BookingProvider } = require("./src/context/bookingStore")
const { QueryClientProvider, QueryClient } = require("react-query")
require('./src/scss/style.scss')


exports.wrapRootElement = ({ element }) => {
    const queryClient = new QueryClient()
    return (
        <BookingProvider>
            <QueryClientProvider client={queryClient}>
                {element}
            </QueryClientProvider>
        </BookingProvider>
    )
}